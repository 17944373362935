/* ======== sidebar ======== */
/* 36 */
.vertical-menu {
  width: 185px;
}

.navbar-brand-box {
  width: 185px;
}

.main-content {
  margin-left: 185px;
}
/* 120 */
#sidebar-menu ul li ul.sub-menu li a {
  margin: 0.4rem 1.5rem 0.4rem 1.9rem;
  padding: 0;
  font-size: 15px;
  color: #464b50;
}

/* 370 */
/* main bg color */
body[data-sidebar='dark'] .vertical-menu {
  background: #48484a;
}

/* 379 */
body[data-sidebar='dark'] #sidebar-menu ul li a {
  color: #f0f0f0;
}

/* 386 */
body[data-sidebar='dark'] #sidebar-menu ul li a:hover {
  color: #f0f0f0;
}

/* body[data-sidebar=dark] #sidebar-menu ul li a[aria-expanded=true] {
    padding-bottom: 0;
} */

/* 402 */
body[data-sidebar='dark'] #sidebar-menu ul li ul.sub-menu li a {
  color: #f0f0f0;
  background: transparent;
  font-size: 14px;
}

/* 406 */
body[data-sidebar='dark'] #sidebar-menu ul li ul.sub-menu li a:hover {
  color: #f0f0f0;
}

/* 416 */
body[data-sidebar='dark'] #sidebar-menu ul > li > a.mm-active {
  background-color: transparent;
}

/* 478 */
body[data-sidebar='dark'] .mm-active {
  color: #efeff4 !important;
}

/* 480 */
body[data-sidebar='dark'] .mm-active > a {
  color: #f0f0f0 !important;
  background-color: transparent;
}

/* 490 */
body[data-sidebar='dark'] #sidebar-menu ul li ul.sub-menu li a.active {
  color: #f0f0f0 !important;
  background-color: #858585;
  margin: 0.4rem 1.5rem 0.4rem 1.3rem;
  padding: 3px 11px;
  border-radius: 3px;
}

/* icon */
body[data-sidebar='dark'] #sidebar-menu ul li a i {
  display: none;
}
/* ================ */

.main_bg {
  background-color: #fff;
  margin-top: 25px;
  height: 100%;
}

/* input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
} */

.main_bg_overview {
  background-color: #efeef4;
  margin-top: 25px;
}

.bg_transparent {
  background-color: transparent;
}

.main_bg .bg-transparent {
  background-color: #efeef4 !important;
}

.border_dark {
  border: 1px solid #aeaeb2;
}

.pd3rem {
  padding: 3rem;
}

.pd_40_40_20_40 {
  padding: 40px 40px 20px 40px;
}

.mrgLeft_27 {
  /* margin-left: 27px; */
  height: 8px;
}

.mrgLeft_12 {
  margin-left: 12px;
}

.registred_user_div .btns {
  gap: 10px;
}

.form_section_title {
  color: #48484a;
  font-size: 13px;
  font-weight: 900;
  letter-spacing: -0.24px;
}

.pd_5_20 {
  padding: 5px 20px;
}

.padding_30_40_0_40 {
  padding: 30px 40px 0 40px;
}

.padding_0_30 {
  padding: 0 30px;
}

.padding_50 {
  padding: 50px;
}

.padding_30_50 {
  padding: 30px 50px;
}

.pb_40 {
  padding-bottom: 40px;
}

.allTables .form-select {
  line-height: 1;
  font-size: 12px;
}

.left_position {
  left: -130px !important;
}

.registred_user_div .btns .btn_export_print {
  background-color: #e5e5ea;
  border: 1px solid #858585;
  color: #858585;
  border-radius: 4px;
  padding: 0 5px;
  min-width: 70px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.24px;
  height: 25px;
}

.table-responsive .table {
  border: transparent !important;
}

/* thead[data-test="table-foot"] {
    display: none;
} */

.table-responsive .table,
.table-responsive .table thead,
.table-responsive .table tr,
.table-responsive .table th,
.table-responsive .table tbody,
.table-responsive .table td {
  border: transparent !important;
}

.table-responsive .table thead[data-test='datatable-head'] {
  border-bottom: 2px solid #858585 !important;
}

.table-responsive .table thead th {
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #48484a;
  padding: 10px 12px 20px 12px;
  /* padding: 10px 12px 14px 12px; */
}

.table-responsive .table thead th.sorting {
  cursor: pointer;
}

.table-responsive .table thead th:first-child {
  padding: 10px 12px 20px 4px;
}

.table-responsive .table thead th:last-child {
  padding: 10px 4px 20px 12px;
}

.table-responsive .table tbody td {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #000;
  padding: 4px 12px;
}

.table-responsive .table tbody tr:first-child td {
  padding-top: 20px;
}

.table-responsive .table tbody td:first-child {
  padding-left: 4px;
}

.table-responsive .table tbody td:last-child {
  padding-right: 4px;
}

.btn_GrayList {
  background-color: #aeaeb2 !important;
  border: 1px solid #48484a !important;
  color: #fff;
  border-radius: 4px;
  padding: 2px 8px;
  min-width: 85px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.btn_Dark {
  background-color: #48484a !important;
  border: 1px solid #48484a !important;
  color: #fff;
  border-radius: 4px;
  padding: 2px 8px;
  min-width: 85px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}
.btn_Dark:hover {
  color: #fff;
}

.btn_delete {
  background-color: #ed2d21 !important;
  border: 1px solid #48484a !important;
  color: #fff !important;
  border-radius: 4px;
  padding: 2px 8px;
  min-width: 85px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.btn_Save_blue {
  color: #007aff !important;
}

.btn_drak_Gray {
  background-color: #858585 !important;
  border: 1px solid #48484a !important;
  border-radius: 4px;
  padding: 2px 8px;
  min-width: 85px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.btn_More {
  background-color: #f9f9f9 !important;
  border: 1px solid #48484a !important;
  color: #48484a;
  border-radius: 4px;
  padding: 2px 8px;
  min-width: 85px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.btn_More2 {
  background-color: #f9f9f9 !important;
  border: 1px solid #48484a !important;
  color: #48484a;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  height: fit-content;
}

.change_status_dropdown {
  height: 28px;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  text-indent: 1px !important;
  text-overflow: '' !important;
}

.change_status_dropdown::-ms-expand {
  display: none !important;
}

button a {
  color: inherit;
}

button a:hover {
  color: inherit;
}

.tableHeadBtns {
  padding: 20px 0;
}

.padding_0_0 {
  padding: 0;
}

.tableHeadBtns .left {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}

.tableHeadBtns .left .backBtn {
  font-size: 40px;
  line-height: 30px;
  color: #858585;
}

.tableHeadBtns .form-select {
  padding: 0.375rem 2.5rem 0.375rem 0.75rem;
  /* height: 27px; */
  height: 38px;
  border-color: #48484a;
}

.tableHeadBtns .form-control {
  /* height: 27px; */
  height: 38px;
  border-color: #48484a;
  width: 13rem;
}

.tableHeadBtns button {
  height: 26px;
}

.tableHeadBtns .right {
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}

.tableHeadBtns .right label[for='per_page'] {
  margin-right: 10px;
  font-weight: 100 !important;
  color: #48484a !important;
  font-size: 12px !important;
}

.tableHeadBtns .right #per_page {
  width: 4rem;
  text-align: center;
}

.tableBottomArea .right #viewing_page {
  width: 4rem;
  text-align: center;
  height: 26px;
}

.tableBottomArea .right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
}

.tableBottomArea .right label {
  font-weight: 100 !important;
  color: #48484a !important;
  font-size: 13px !important;
}

.tableBottomArea .right i {
  font-size: 25px;
  cursor: pointer;
}

/* ------  */
.table-responsive .table .label {
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
}

.table-responsive .table input.customer_chkbox {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.table-responsive .table .label:before {
  content: '';
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  /* margin-right: 10px; */
}

.table-responsive .table input.customer_chkbox:checked + .label svg {
  display: block;
  position: absolute;
  top: 6px;
  left: 3px;
  font-size: 10px;
  color: #000;
}

.table-responsive .table input.customer_chkbox + .label svg {
  display: none;
}
.overflow-unset {
  overflow-x: unset !important;
}

.hoverDisable:hover {
  background-color: #f9f9f9;
  border: 1px solid #48484a;
  color: #48484a;
  border-radius: 4px;
  padding: 2px 8px;
  min-width: 85px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

/* .dropdown.show button[aria-expanded="true"] {
    background-color: #F9F9F9;
    border-color: #48484A;
    color: #48484A;
    border-radius: 4px;
    padding: 2px 8px;
    min-width: 85px;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
} */

/* ---- multi-select --- */
.tableHeadBtns .select2-selection__control {
  border-color: #48484a;
}
.tableHeadBtns .select2-selection__control:hover {
  border-color: #48484a;
}
.tableHeadBtns .select2-selection__input input {
  width: 70px !important;
}

.tableHeadBtns .css-1pahdxg-control {
  box-shadow: none;
}

.tableHeadBtns .css-14el2xx-placeholder {
  color: #495057;
  font-size: 12px;
}

.tableHeadBtns .multi-select-container {
  font-size: 12px;
  color: #495057;
}

/* ======== disable input Grayed ======== */
.grayInput[readonly] {
  background-color: #e5e5ea !important;
  color: #5f5f62 !important;
  cursor: not-allowed !important;
}

/* ===== view only access ==== */
.notAllowedLink {
  cursor: not-allowed !important;
  opacity: 0.7;
}
.notAllowed:disabled {
  cursor: not-allowed !important;
  opacity: 0.7;
}
.notAllowedInput:disabled {
  background-color: #e5e5ea !important;
  color: #5f5f62 !important;
  cursor: not-allowed !important;
}
.notAllowedPopup:disabled {
  background-color: #e5e5ea !important;
  color: #5f5f62 !important;
  cursor: not-allowed !important;
  border-color: hsl(210deg 14% 83%) !important;
}
.notAllowCheckMark::before {
  vertical-align: top !important;
}
.notAllowCheckMark::after {
  content: '';
  position: absolute;
  -webkit-appearance: none;
  background-color: #ccc;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 7px;
  display: inline-block;
  cursor: not-allowed !important;
  opacity: 0.7;
  left: 0px;
  top: 0px;
}

/* ---- disable react-select in view only access --- */
.css-1insrsq-control {
  background-color: #e5e5ea !important;
  color: #5f5f62 !important;
  cursor: not-allowed !important;
  border-color: hsl(210deg 14% 83%) !important;
}

/* ======== profile menu ========= */
.profilerMenu {
  z-index: 2000;
}
.profilerMenu .usernName {
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: -0.24px;
  color: #000;
}
.profilerMenu .userEmail {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: -0.24px;
  color: #858585;
}
.profilerMenu .logoutLink span {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #000;
}

.profilerMenu.show {
  top: 90% !important;
}

/* -------- asc and desc Css -------- */

.table-responsive .table thead th.sorting::after {
  font-family: 'Font Awesome 5 Free';
  content: '\f0dd';
  font-weight: 900;
  display: inline-block;
  vertical-align: middle;
  opacity: 1;
  padding-left: 12px;
  padding-bottom: 9px;
  line-height: 0;
  font-size: 20px;
  /* color: #aeaeb2; */
  color: #48484a;
  transition: transform 100ms;
}

.table-responsive .table thead th.lowToHigh::after {
  transform: rotate(180deg);
  padding-right: 12px;
  /* color: #1d1d1d; */
  color: #f8b904;
  transition: transform 100ms;
}

/* ----------------- */
.card .card-body .form-group .form-control:focus {
  color: #48484a;
  background-color: #fff;
  border-color: #48484a;
  outline: 0;
  box-shadow: none;
}

.card .card-body .form-group .form-control {
  font-size: 12px;
  line-height: 24px;
  letter-spacing: -0.24px;
  font-weight: 400;
  color: #000;
}

.card.formSection .card-body.formCard .form-group .form-control {
  /* margin-left: 27px; */
  /* margin-right: 27px; */
  max-width: 400px;
}

.card .card-body .form-group label {
  font-size: 13px;
  letter-spacing: -0.24px;
  font-weight: 700;
  color: #48484a;
}

.formCard .btns-top {
  gap: 15px;
  margin: 22px 0;
}

.formCard .btns-top button {
  background-color: #aeaeb2;
  border: 1px solid #48484a;
  color: #fff;
  border-radius: 4px;
  padding: 0 5px;
  min-width: 85px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.24px;
  height: 25px;
}

.cardTextArea {
  border: 1px solid #aeaeb2;
  border-radius: 0;
}

.cardTextArea label {
  line-height: 1;
}

.cardTextArea input {
  height: 27px;
  font-size: 13px;
}

.cardTextArea input:focus {
  border-color: #ced4da;
}

.cardTextArea textarea {
  height: 420px;
  max-height: 420px;
  min-height: 420px;
  /* padding-top: 50px; */
  padding-bottom: 30px;
  font-size: 13px;
}

.cardTextArea textarea:focus {
  border-color: #ced4da;
}

/* .prosTable .dataTables_wrapper .dataTables_length label::before {
    content: "Per Page";
} */
/* ------------------ */

.systemCard {
  border: 1px solid #48484a;
  border-radius: 0;
}

.systemCard h5 {
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #48484a;
  margin-bottom: 0.7rem;
}

.systemCard label {
  margin-bottom: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #48484a;
  letter-spacing: -0.24px;
}

.systemCard label {
  line-height: 1;
}

.systemCard .checkBox {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

/* ----  */
.systemCard .label {
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
}

.systemCard input.customer_chkbox {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.systemCard .label:before {
  content: '';
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  /* margin-right: 10px; */
}

.systemCard input.customer_chkbox:checked + .label svg {
  display: block;
  position: absolute;
  top: 4px;
  left: 3px;
  font-size: 10px;
  color: #000;
}

.systemCard input.customer_chkbox + .label svg {
  display: none;
}
/* -----  */
/* =============== */

.invoiceCard {
  background-color: #e5e5ea;
  border: 1px solid #48484a;
  border-radius: 0;
}

/* ======== date popup ====== */

.datePopupBtn {
  border: 1px solid #ced4da;
  background: #fff;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  /* margin-left: 27px; */
  /* margin-right: 27px; */
  max-width: 400px;
  width: 100%;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #000;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-align: left;
}

.datePopupBtn:hover {
  background-color: #fff;
  border: 1px solid #ced4da;
  color: #484848;
  border-radius: 4px;
  min-width: 85px;
  font-size: 13px;
  font-weight: 400;
  padding: 0.375rem 0.75rem;
}

.popUpMain_Date button[aria-expanded='true'] {
  background-color: #f9f9f9;
  border-color: #484848;
  color: #48484a;
  padding: 0.375rem 0.75rem !important;
  min-width: 85px;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  line-height: 20px !important;
}

.datePopupMenu {
  left: 25px !important;
  width: 300px;
  padding: 21px 16px;
}

.datePopupMenu .checkBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 12px !important;
  margin-left: 2px;
}
/* -------- */

.btn_delete:hover {
  background-color: #ed2d21;
  border: 1px solid #48484a;
  color: #fff;
  border-radius: 4px;
  padding: 2px 8px;
  min-width: 85px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.btn_append {
  background-color: #858585;
  color: #fff;
  border-radius: 0 5px 5px 0px;
  border: none;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  min-width: 100px;
  min-width: 100px;
}

.popUpMain_DeleteBtn button[aria-expanded='true'] {
  background-color: #ed2d21 !important;
  border: 1px solid #48484a !important;
  color: #fff !important;
  border-radius: 4px !important;
  padding: 2px 8px !important;
  min-width: 85px !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.popUpMain_DeleteBtn .deletePopupMenu {
  width: 215px;
  padding: 21px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 13px;
}

.popUpMain_DeleteBtn .deletePopupMenu .title {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.24px;
  color: #000000;
}

.popUpMain_DeleteBtn .deletePopupMenu .confirm {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.24px;
  color: #ec2d20;
  width: 80%;
  white-space: normal;
}

.popUpMain_DeleteBtn .deletePopupMenu .btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.btn-check:checked + .btn-secondary,
.btn-check:active + .btn-secondary,
.btn-secondary:active,
.btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  color: unset !important;
  background-color: unset !important;
  border-color: unset !important;
}

/* #react-select-22-listbox {
    background-color: red !important;
} */

/* ========== flatpicker ========= */
.tableHeadBtns input.flatpickr-input + input.input {
  width: 150px;
}

.flatpickr-weekdays,
span.flatpickr-weekday,
.flatpickr-months .flatpickr-month,
.flatpickr-current-month .flatpickr-monthDropdown-months {
  background-color: #48484a !important;
}

.flatpickr-day.selected {
  background-color: #f8b904 !important;
  border-color: transparent !important;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f8b904 !important;
}

/* ------ monthSelect ------ */
.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flatpickr-monthSelect-months {
  margin: 3px 1px 3px 1px !important;
}

.flatpickr-monthSelect-month.selected {
  background-color: #f8b904 !important;
  color: #fff;
}
/* ========== flatpicker ========= */

/* --------------- */
.announReactSelect {
  max-width: 400px;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.select2-selection__placeholder,
.css-14el2xx-placeholder {
  font-size: 12px;
}

.css-qc6sy-singleValue {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.24px;
  font-weight: 400;
  color: #000;
}

.manager-form .form-control {
  margin: 0 !important;
}

.manager-form .mrgLeft_27 {
  margin: 0 !important;
}

.no-input-margin .form-control {
  margin: 0 !important;
}
.invoice-next-prev-btn {
  border: none;
  font-size: 35px;
}
.input-group-text {
  height: 100%;
}

.disabled-option {
  background-color: #7c7c7c;
  color: #fff;
  opacity: 0.4;
  cursor: not-allowed !important;
}

.flatpickr-day.today {
  border-color:#fff !important;
}
.btnBlue {
  font-weight: 400;
  font-size: 12px;
  background: #007AFF !important;
  border: 1px solid #48484A;
  border-radius: 4px;
  padding: 4px 25px;
  cursor: pointer;
}
.btnBlue:hover {
  background: #007AFF !important;
}
.btnBlue:focus {
  background: #007AFF !important;
}